/* eslint-disable prettier/prettier */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IBaseConfig } from "@portal/common/config/config.model";
import { commonEnvironment } from "@portal/common/environment/environment";
import { NgxLoggerLevel } from "ngx-logger";

export const environment: IBaseConfig = {
    ...commonEnvironment,
    APP_BASE_PATH: 'http://waki-dev-frontend.s3-website.eu-west-3.amazonaws.com',
    API_BASE_PATH: 'https://api.waki.me',
    // APP_BASE_PATH: 'https://uat-portal.waki.me',
    // API_BASE_PATH: 'https://uat-api.waki.me',
    IMAGE_BASE_PATH: 'https://resources.waki.me',
    SOCKET_URL: 'wss://api.waki.me',
    featureFlags: {
        ...commonEnvironment.featureFlags,
    },
    logging: {
        disableConsoleLogging: false,
        logLevel: NgxLoggerLevel.DEBUG,
        enableSourceMaps: true,
        serverLoggingUrl: null,
    },
    recaptcha: {
        siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
